<template>
  <v-dialog :value="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="text-h5"> Create System Prompt </v-card-title>

      <div class="px-5 my-5">
        <v-textarea
            label="Name"
            placeholder="Name"
            outlined
            v-model="name"
        ></v-textarea>
      </div>

      <div class="px-5 my-5">
        <v-textarea
            label="Prompt"
            placeholder="Prompt"
            outlined
            v-model="prompt"
        ></v-textarea>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="create"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapActions: AIChatActions,
} = createNamespacedHelpers('AIChat');

export default {
  name: 'EditSystemPrompt',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      prompt: '',
      name: '',
    }
  },
  computed: {},
  methods: {
    ...AIChatActions(['createSystemPrompt', 'getSystemPrompts']),
    async create() {
      if(this.prompt === '' || this.name === '') {
        return;
      }
      await this.createSystemPrompt({ prompt : this.prompt, name: this.name });
      this.$emit('close');
      await this.getSystemPrompts();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index';
</style>
