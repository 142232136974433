<template>
  <div class="Promts">
    <v-row class="Promts__filter-row">
      <v-col>
        <v-btn text small color="primary" @click="isCreateDialogOpen = true">
          + Add System Prompt
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
        class="Promts__data-table elevation-0"
        outlined
        item-key="id"
        :item-class="() => 'SystemPrompts__data-table-row'"
        :items="systemPrompts"
        :headers="headers"
        :loading="systemPromptLoading"
    >
      <template v-slot:[`item.type`]="{ item }">
        <v-chip small>
          <strong>
            {{ item.type?.toUpperCase() }}
          </strong>
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="error" @click.stop="triggerEdit(item)">
          <v-icon small> mdi-pencil-outline </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <create-system-prompts :dialog="isCreateDialogOpen" @close="isCreateDialogOpen = false" />
    <edit-system-prompt :dialog="isEditDialogOpen" @close="isEditDialogOpen = false" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import CreateSystemPrompts from "@/components/modals/system-prompt/create-system-prompts.vue";
import EditSystemPrompt from "@/components/modals/system-prompt/edit-system-prompt.vue";

const {  mapGetters: AIChatGetters, mapActions: AIChatActions, mapMutations: AIChatMutations } =
    createNamespacedHelpers('AIChat');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'SystemPromptTab',
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      isCreateDialogOpen: false,
      isEditDialogOpen: false,
    };
  },
  components: {
    CreateSystemPrompts,
    EditSystemPrompt,
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.getSystemPrompts();
    },
  },
  computed: {
    ...AIChatGetters({
      systemPrompts: 'SYSTEM_PROMPTS',
      systemPromptLoading: 'SYSTEM_PROMPT_LOADING',
      updateSystemPromptLoading: 'UPDATE_SYSTEM_PROMPT_LOADING'
    }),
    ...customerGetters({
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
  },
  mounted() {
    this.getSystemPrompts();
  },
  methods: {
    ...AIChatActions(['updateSystemPrompt', 'getSystemPrompts']),
    ...AIChatMutations({
      setEdit: 'SET_EDIT_SYSTEM_PROMPT'
    }),
    triggerEdit(item) {
      this.setEdit(item);
      this.isEditDialogOpen = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../prompts-page';
</style>
