<template>
  <v-dialog :value="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="text-h5"> Edit System Prompt </v-card-title>

      <div class="px-5 my-5">
        <v-textarea
            label="Name"
            placeholder="Name"
            outlined
            v-model="edit.name"
        ></v-textarea>
      </div>

      <div class="px-5 my-5">
        <v-textarea
            label="Prompt"
            placeholder="Prompt"
            outlined
            v-model="edit.prompt"
        ></v-textarea>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="update"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapActions: AIChatActions,
  mapGetters: AIChatGetters,
} = createNamespacedHelpers('AIChat');

export default {
  name: 'EditSystemPrompt',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...AIChatGetters({
      edit: 'GET_EDIT_SYSTEM_PROMPT',
    })
  },
  methods: {
    ...AIChatActions(['updateSystemPrompt', 'getSystemPrompts']),
    async update() {
      await this.updateSystemPrompt({ systemPromptId: this.edit?.id, prompt: this.edit?.prompt, name: this.edit?.name });
      this.$emit('close');
      await this.getSystemPrompts();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index';
</style>
