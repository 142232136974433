<template>
  <v-dialog :value="dialog" persistent max-width="400" @click:outside="$emit('close')">
    <v-card>
      <v-card-title class="text-h5"> Edit Session Name </v-card-title>

      <div class="px-5 my-5">
        <v-text-field
            label="Session Name"
            placeholder="Session Name"
            outlined
            v-model="edit.name"
        ></v-text-field>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="updateName"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapActions: AIChatActions,
  mapMutations: AIChatMutations,
  mapGetters: AIChatGetters,
} = createNamespacedHelpers('AIChat');

export default {
  name: 'EditSessionName',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...AIChatGetters({
      edit: 'GET_EDIT_SESSION',
    })
  },
  methods: {
    ...AIChatActions(['updateSession']),
    ...AIChatMutations({
      updateName: 'CHANGE_SESSION_NAME',
    }),
    async updateName() {
      await this.updateSession({ sessionId: this.edit?.id, name: this.edit?.name })
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './edit-session-modal';
</style>
