<template>
  <v-dialog :value="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="text-h5"> Create Tool </v-card-title>

      <div class="px-5 my-5">
        <v-text-field
          label="Tool Name"
          placeholder="Tool Name"
          outlined
          v-model="ToolName"
        ></v-text-field>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="addTool"> Create </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions: ToolsActions } = createNamespacedHelpers('promptsTools');

export default {
  name: 'CreateTool',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ToolName: '',
    };
  },
  methods: {
    ...ToolsActions(['createTool']),
    addTool() {
      this.createTool({
        name: this.ToolName,
        type: 'function',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './create-tool';
</style>
