<template>
  <div class="Promts">
    <v-row class="Promts__filter-row">
      <v-col>
        <v-btn text small color="primary" @click="isDialogOpen = true">
          + Add Prompts
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
        class="Promts__data-table elevation-0"
        outlined
        item-key="id"
        :item-class="() => 'Promts__data-table-row'"
        :items="promts"
        :headers="headers"
        :loading="fetchPromtsLoading || deletePromtLoading"
        @click:row="handleGoToPromt"
    >
      <template v-slot:[`item.type`]="{ item }">
        <v-chip small>
          <strong>
            {{ item.type?.toUpperCase() }}
          </strong>
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="error" @click.stop="triggerDelete(item)">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <action-prompt
        v-model="showDeletePromtModal"
        v-if="showDeletePromtModal"
        action-text="Delete"
        :action="removePromt"
        :loading="deletePromtLoading"
        header="Delete Promt"
        :body="deleteMessageBody"
        action-type="error"
    />

    <create-promt :dialog="isDialogOpen" @close="isDialogOpen = false" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ActionPrompt from '@/components/modals/action-prompt/action-prompt.vue';
import CreatePromt from '@/components/modals/create-promt/create-promt.vue';

const {  mapGetters: promtsGetters, mapActions: promtsActions } =
    createNamespacedHelpers('promts');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'BrowserPromts',
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      promtCount: null,
      promtId: null,
      showDeletePromtModal: false,
      isDialogOpen: false,
    };
  },
  components: {
    ActionPrompt,
    CreatePromt
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.fetchPromts();
    },
  },
  computed: {
    ...promtsGetters({
      promts: 'PROMTS',
      fetchPromtsLoading: 'FETCH_PROMTS_LOADING',
      deletePromtLoading: 'DELETE_PROMT_LOADING',
    }),
    ...customerGetters({
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    deleteMessageBody() {
      const lineOne = 'Are you sure you want to delete this promt?';
      const lineTwo = `There are ${this.promtCount} workflows using this promt.`;
      let message = lineOne;
      if (this.promtCount) message += `\n\n${lineTwo}`;
      return message;
    },
  },
  mounted() {
    this.fetchPromts();
  },
  methods: {
    ...promtsActions(['fetchPromts', 'deletePromt']),
    triggerDelete({ id, count }) {
      this.promtCount = count;
      this.promtId = id;
      this.showDeletePromtModal = true;
    },
    async removePromt() {
      await this.deletePromt(this.promtId);
      this.promtCount = null;
      this.promtId = null;
      this.showDeletePromtModal = false;
    },
    handleGoToPromt(item) {
      this.$router.push({ path: `/prompts/${item.id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../prompts-page';
</style>
